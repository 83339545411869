import { ActionContext } from 'vuex'

import { DomainType } from '@/common/helpers/enums/domain-type.enum'
import { InternalCustomFields } from '@/common/helpers/enums/fields.enum'
import { SystemTypes } from '@/common/helpers/enums/system-types'
import { isSystemField } from '@/common/helpers/utils/isSystemField'

import CustomFieldService from '@/common/services/custom-fields'

import { RootState } from '@/store/interfaces'
import { CustomField } from '@/store/interfaces/custom-field'

import { CustomFieldState } from './interfaces'
import * as types from './mutation-types'

export const fetchConversationCustomFields = async (
  context: ActionContext<CustomFieldState, RootState>
) => {
  const customFieldService = new CustomFieldService()

  const conversationCustomFields =
    await customFieldService.getConversationCustomFields()

  context.commit(types.SET_CONVERSATION_CUSTOM_FIELDS, conversationCustomFields)
}

export const fetchCustomFields = async (
  context: ActionContext<CustomFieldState, RootState>
) => {
  const customFieldService = new CustomFieldService()

  const customFields = await customFieldService.getCustomFields()

  context.commit(types.SET_CUSTOM_FIELDS, customFields)
}

export const fetchOrganizationCustomFields = async (
  context: ActionContext<CustomFieldState, RootState>
) => {
  const customFieldService = new CustomFieldService()

  const organizationCustomFields =
    await customFieldService.getOrganizationCustomFields()

  context.commit(types.SET_ORGANIZATION_CUSTOM_FIELDS, organizationCustomFields)
}

export const fetchListCustomFields = async (
  context: ActionContext<CustomFieldState, RootState>,
  type: SystemTypes
) => {
  try {
    const customFieldService = new CustomFieldService()

    const customFields = await customFieldService.getCustomFields(type)

    const sortedData = customFields.sort((a, b) => {
      // Sort by isEnabled = false
      if (a.isEnabled !== b.isEnabled) {
        return a.isEnabled ? -1 : 1
      }

      // Sort by default fields
      const aIsDefault = isSystemField(a.type)
      const bIsDefault = isSystemField(b.type)

      if (aIsDefault && bIsDefault) {
        return 0
      } else if (aIsDefault) {
        return -1
      } else if (bIsDefault) {
        return 1
      }

      // Sort by order
      if (a.order !== b.order) {
        return a.order - b.order
      }

      return 0
    })

    context.commit(types.SET_TABLE_DATA, sortedData)

    return sortedData
  } catch (err) {
    throw new Error((err as any).message)
  }
}

export const fetchListPaginatedCustomFields = async (
  context: ActionContext<CustomFieldState, RootState>,
  params: {
    title?: string
    take?: number
    skip?: number
    domainType?: DomainType
    isEnabled?: boolean
    includeExcludedRecords?: boolean
    required?: boolean
    type?: InternalCustomFields
  }
) => {
  try {
    const customFieldService = new CustomFieldService()

    const data = await customFieldService.getPaginatedCustomFields(params)

    const sortedData = data?.customFields.sort((a, b) => {
      // Sort by isEnabled = false
      if (a.isEnabled !== b.isEnabled) {
        return a.isEnabled ? -1 : 1
      }

      // Sort by default fields
      const aIsDefault = isSystemField(a.type)
      const bIsDefault = isSystemField(b.type)

      if (aIsDefault && bIsDefault) {
        return 0
      } else if (aIsDefault) {
        return -1
      } else if (bIsDefault) {
        return 1
      }

      // Sort by order
      if (a.order !== b.order) {
        return a.order - b.order
      }

      return 0
    })

    context.commit(types.SET_TABLE_DATA, sortedData)

    return { ...data, customFields: sortedData }
  } catch (err) {
    throw new Error((err as any).message)
  }
}

export const postReorderFields = async (
  context: ActionContext<CustomFieldState, RootState>,
  list: CustomField[]
) => {
  try {
    const customFieldService = new CustomFieldService()

    await customFieldService.postReorderList(list)
  } catch (err) {
    throw new Error((err as any).message)
  }
}

export const fetchCustomField = async (
  context: ActionContext<CustomFieldState, RootState>,
  id: string
) => {
  try {
    const customFieldService = new CustomFieldService()

    return await customFieldService.getFieldById(id)
  } catch (err) {
    throw new Error((err as any).message)
  }
}

export const setCustomFieldType = (
  context: ActionContext<CustomFieldState, RootState>,
  fieldType: string
) => {
  context.commit(types.SET_CUSTOM_FIELD_TYPE, fieldType)
}

export const setOrderNewItem = (
  context: ActionContext<CustomFieldState, RootState>,
  order: number
) => {
  context.commit(types.SET_NEW_ORDER_ITEM, order)
}

export const postCustomField = async (
  context: ActionContext<CustomFieldState, RootState>,
  customField: CustomField
) => {
  try {
    const customFieldService = new CustomFieldService()

    const result = await customFieldService.postCustomField(customField)
    if (result?.error) {
      return { error: result.error }
    }

    return result
  } catch (err) {
    console.error('Error creating custom field:', err)
    throw new Error(err)
  }
}

export const putCustomField = async (
  context: ActionContext<CustomFieldState, RootState>,
  customField: CustomField
) => {
  try {
    const customFieldService = new CustomFieldService()

    const result = await customFieldService.putCustomField(customField)
    if (result?.error) {
      return { error: result.error }
    }

    return result
  } catch (err) {
    throw new Error((err as any).message)
  }
}

export const patchEnableCustomField = async (
  context: ActionContext<CustomFieldState, RootState>,
  id: string
) => {
  try {
    const customFieldService = new CustomFieldService()

    const result = await customFieldService.patchEnableCustomField(id)
    if (result?.error) {
      return { error: result.error }
    }

    return result
  } catch (err) {
    throw new Error((err as any).message)
  }
}

export const patchDisableCustomField = async (
  context: ActionContext<CustomFieldState, RootState>,
  id: string
) => {
  try {
    const customFieldService = new CustomFieldService()

    const result = await customFieldService.patchDisableCustomField(id)
    if (result?.error) {
      return { error: result.error }
    }

    return result
  } catch (err) {
    throw new Error((err as any).message)
  }
}

export const deleteCustomField = async (
  context: ActionContext<CustomFieldState, RootState>,
  id: string
) => {
  try {
    const customFieldService = new CustomFieldService()

    await customFieldService.deleteCustomField(id)
  } catch (err) {
    throw new Error((err as any).message)
  }
}
