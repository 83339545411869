import { GlobalAlert, GlobalAlertState } from '@/store/interfaces'

import * as types from './mutations-types'

export default {
  [types.UNSHIFT_GLOBAL_ALERT](state: GlobalAlertState, alert: GlobalAlert) {
    state.globalAlerts = [...state.globalAlerts, alert]
  },
  [types.REMOVE_GLOBAL_ALERT](state: GlobalAlertState, alert: GlobalAlert) {
    state.globalAlerts = state.globalAlerts.filter(item => item === alert)
  },
  [types.CLEAR_GLOBAL_ALERTS](state: GlobalAlertState) {
    state.globalAlerts = []
  }
}
