import { ActionContext } from 'vuex'

import { RootState } from '@/store/interfaces'

import { InactivityEventsService } from '../inacitivity-events'
import { InactivityChannel } from '../types'
import { AutomationsState } from './interfaces'
import * as types from './mutations-types'

const inactivityEventsService = new InactivityEventsService()

export const getEvents = async (
  context: ActionContext<AutomationsState, RootState>
) => {
  const { data } = await inactivityEventsService.getEvents()
  const { events: eventsData } = data

  const processedEvents = eventsData.map((event: InactivityChannel) => ({
    ...event,
    channel: event.channel?.replace('automation-chat-contact-inactivity-', '')
  }))

  context.commit(types.SET_EVENTS, processedEvents)
  return processedEvents
}

export const createEvents = async (
  context: ActionContext<AutomationsState, RootState>,
  events: InactivityChannel[]
) => {
  const { data } = await inactivityEventsService.createEvents(events)
  const { events: eventsData } = data

  context.commit(types.CREATE_EVENTS, eventsData)
  return data
}

export const updateEvents = async (
  context: ActionContext<AutomationsState, RootState>,
  events: InactivityChannel[]
) => {
  const { data } = await inactivityEventsService.updateEvents(events)
  const { events: eventsData } = data

  context.commit(types.UPDATE_EVENTS, eventsData)
  return data
}
