import { RoleTypes } from '@/common/helpers/enums/role-types'

export default {
  path: 'octaflow',
  name: 'octaflow',
  component: () => import('@/views/OctaFlow.vue'),
  redirect: '/octaflow/automations',
  children: [
    {
      name: 'automations',
      path: 'automations',
      component: () => import('@/modules/OctaFlow/automations/Automations.vue'),
      meta: {
        permissionLevel: RoleTypes.Agent
      }
    }
  ]
}
