import { WozContentState } from './interfaces'

export const contentGapsList = ({ contentGapsList }: WozContentState) => {
  return contentGapsList
}

export const agentDocumentsToDelete = ({ agent }: WozContentState) =>
  agent.documentsToDelete

export const agentDocumentsToLink = ({ agent }: WozContentState) =>
  agent.documentsToLink
