import VueRouter from 'vue-router'

import { firstBuyRoutes } from '@/modules/Billing/first-buy-routes'
import { ticketSurveyRoutes } from '@/modules/Ticket/ticket-survey-routes'

import store from '@/store'

const routes = [
  {
    path: '',
    redirect: '/login'
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/Login.vue')
  },
  {
    path: '/ssologin/:tenantId',
    name: 'ssologin',
    component: () => import('@/views/SSORedirectLogin.vue')
  },
  {
    path: '/sso-tenant-select',
    name: 'sso-tenant-select',
    component: () => import('@/modules/Auth/SSOTenantSelect.vue'),
    beforeEnter: (to, from, next) => {
      const ssoTenants = store.getters.ssoTenants

      if (!ssoTenants.length) {
        next('/login')
      }

      next()
    }
  },
  {
    path: '/sign-up',
    name: 'SignUp',
    redirect: '/sign-up/email-step',
    component: () => import('@/views/SignUp.vue'),
    children: [
      {
        name: 'email-step',
        path: 'email-step',
        component: () => import('@/modules/Auth/Signup/SignupContent.vue')
      },
      {
        name: 'company-data',
        path: 'company-data',
        component: () => import('@/modules/Auth/Signup/CompanyData.vue')
      },
      {
        name: 'phone-validation',
        path: 'phone-validation',
        component: () => import('@/modules/Auth/Signup/PhoneValidation.vue')
      }
    ]
  },
  {
    path: '/new-password',
    name: 'NewPassword',
    component: () => import('@/views/NewPassword.vue')
  },
  {
    path: '/invite-agent',
    name: 'InviteAgent',
    component: () => import('@/views/InviteAgent.vue')
  },
  {
    path: '/tray/',
    name: 'Tray',
    component: () => import('@/views/Tray.vue'),
    children: [
      {
        name: 'auth',
        path: 'auth',
        component: () => import('@/modules/Tray/Tray.vue')
      },
      {
        name: 'landing-page',
        path: 'callback',
        component: () => import('@/modules/Tray/LandingPage.vue')
      },
      {
        name: 'no-permission',
        path: 'no-permission',
        component: () => import('@/modules/Tray/NoPermission.vue')
      }
    ]
  },
  {
    path: '/whitelabel/:code',
    name: 'Whitelabel',
    component: () => import('@/views/Whitelabel.vue')
  },
  {
    path: '/blocked-account',
    name: 'blocked-account',
    component: () => import('@/modules/Auth/BlockedAccount/BlockedAccount.vue')
  },
  firstBuyRoutes,
  ticketSurveyRoutes,
  {
    path: '/*',
    redirect: '/login'
  }
]

const router = new VueRouter({
  mode: 'history',
  base: import.meta.env.BASE_URL,
  routes
})

export default router
