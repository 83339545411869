import { WOZ_AGENT_EDIT_TAB } from '@/modules/Woz/enums'

import { WOZAgentsState, WozAgent } from './interfaces'

const defaultAgent: WozAgent = {
  voice: '',
  wordsToAvoid: [],
  instructions: '',
  isWoz: false,
  name: '',
  onlyMyContent: true,
  active: true,
  specialties: []
}

const state: WOZAgentsState = {
  agents: null,
  loadingAgents: false,
  initialCurrentAgent: undefined,
  currentAgent: undefined,
  defaultAgent,
  copilotAgent: undefined,
  currentTab: WOZ_AGENT_EDIT_TAB.PROFILE,
  saveModaIsVisible: {
    show: false,
    to: undefined
  },
  activeProfileAlerts: {
    open: false,
    title: '',
    description: ''
  }
}

export default state
