import { ActionContext } from 'vuex'

import dayjs from 'dayjs'

import {
  getWozAgents,
  toggleEnable,
  getWozAgent,
  updateAgent,
  deleteAgent
} from '@/modules/Woz/agents/agents.service'
import {
  linkContentToAgent,
  unlinkAgentDocuments
} from '@/modules/Woz/content/content.service'
import { WOZ_AGENT_EDIT_TAB, DOCUMENT_STATUS } from '@/modules/Woz/enums'

import i18n from '@/common/i18n'

import { RootState } from '@/store/interfaces'

import { WOZAgentsState, WozAgent } from './interfaces'
import * as types from './mutation-types'

export const loadAgents = async (
  context: ActionContext<WOZAgentsState, RootState>,
  payload: {
    name?: string
    active?: boolean
  }
) => {
  context.commit(types.SET_WOZ_AGENTS_LOADING, true)

  context.dispatch('updateCurrentWozAgent', null)

  const agents = await getWozAgents(payload)
    .catch(ex => console.error('Error on get woz agents', { ex }))
    .finally(() => context.commit(types.SET_WOZ_AGENTS_LOADING, false))

  const wozCopilotAgent = agents.find((d: WozAgent) => d.isWoz)

  context.commit(
    types.SET_WOZ_AGENTS,
    agents.filter((d: WozAgent) => !d.isWoz)
  )

  if (Object.keys(payload).length === 0) {
    context.commit(types.SET_WOZ_COPILOT_AGENT, wozCopilotAgent)
  }
}

export const toggleAgentState = async (
  context: ActionContext<WOZAgentsState, RootState>,
  payload: {
    id: string
    state: boolean
  }
) => {
  const { id, state } = payload

  await toggleEnable(id, state).catch(ex =>
    console.error('Error on disable agent', { ex, id })
  )

  const agents = context.state.agents

  if (agents) {
    const agent = agents.find(agent => agent.id === id)

    if (agent) {
      agent.active = state

      context.commit(types.SET_WOZ_AGENTS, agents)
    }
  }
}

export const getAgent = async (
  context: ActionContext<WOZAgentsState, RootState>,
  id: string
) => {
  const agent = id
    ? await getWozAgent(id).catch(ex =>
        console.error('Error on get agent', { ex, id })
      )
    : {
        voice: '',
        wordsToAvoid: [],
        instructions: '',
        isWoz: false,
        name: '',
        onlyMyContent: true,
        active: false,
        specialties: []
      }

  agent.specialties = agent.specialties ?? []

  context.dispatch('updateInitialCurrentWozAgent', agent)

  context.dispatch('updateCurrentWozAgent', agent)

  return agent
}

export const upsertWozAgent = async (
  context: ActionContext<WOZAgentsState, RootState>
) => {
  try {
    const { currentAgent, currentTab } = context.getters

    const name = currentAgent.name

    const i18nDraft = i18n?.t('woz.agents.edit.profile.name.draft')

    const parsedCurrentAgent = {
      ...currentAgent,
      name:
        name !== ''
          ? name
          : `${i18nDraft} (${dayjs().format('DD/MM/YYYY HH:mm')})`
    }

    const id = await updateAgent(parsedCurrentAgent).catch(ex =>
      console.error('Error on update agent', { ex, currentAgent })
    )

    const agentDocumentsToDelete =
      context.rootGetters['wozContent/agentDocumentsToDelete']

    if (agentDocumentsToDelete.length > 0) {
      try {
        await unlinkAgentDocuments(id, agentDocumentsToDelete)
      } catch (error) {
        console.error('Error on linkContentToAgent', error)
      }
    }

    const agentDocumentsToLink =
      context.rootGetters['wozContent/agentDocumentsToLink']

    if (agentDocumentsToLink.length > 0) {
      try {
        await linkContentToAgent(id, agentDocumentsToLink)
      } catch (error) {
        console.error('Error on linkContentToAgent', error)
      }
    }

    context.dispatch(
      'dispatchAlert',
      {
        body: i18n?.t('woz.agents.edit.alerts.save.success'),
        variant: 'success'
      },
      { root: true }
    )

    if (currentTab === WOZ_AGENT_EDIT_TAB.INSTRUCTIONS && !!currentAgent?.id) {
      context.dispatch('setWozProfileCurrentTab', WOZ_AGENT_EDIT_TAB.CONTENT)
    }

    const updatedCurrentAgent = {
      ...parsedCurrentAgent,
      id
    }

    context.dispatch('updateInitialCurrentWozAgent', updatedCurrentAgent)

    context.dispatch('updateCurrentWozAgent', updatedCurrentAgent)
  } catch {
    context.dispatch(
      'dispatchAlert',
      {
        body: i18n?.t('woz.agents.edit.alerts.save.error'),
        variant: 'danger'
      },
      { root: true }
    )
  }
}

export const updateInitialCurrentWozAgent = async (
  context: ActionContext<WOZAgentsState, RootState>,
  agent: WozAgent
) => {
  context.commit(types.SET_WOZ_PROFILE_INITIAL_CURRENT_AGENT, agent)
}

export const updateCurrentWozAgent = async (
  context: ActionContext<WOZAgentsState, RootState>,
  agent: WozAgent
) => {
  context.commit(types.SET_WOZ_CURRENT_AGENT, agent)
}

export const deleteWozAgent = async (
  context: ActionContext<WOZAgentsState, RootState>,
  id: string
) => {
  await deleteAgent(id).then(() => {
    const agents = context.state.agents

    if (agents) {
      const agentIndex = agents.findIndex(agent => agent.id === id)

      if (agentIndex >= 0) {
        agents.splice(agentIndex, 1)

        context.commit(types.SET_WOZ_AGENTS, agents)
      }
    }
  })
}

export const setWozProfileCurrentTab = (
  context: ActionContext<WOZAgentsState, RootState>,
  tab: WOZ_AGENT_EDIT_TAB
) => {
  context.commit(types.SET_WOZ_PROFILE_CURRENT_TAB, tab)
}

export const setSaveModalVisibility = (
  context: ActionContext<WOZAgentsState, RootState>,
  payload: {
    show: boolean
    to: any
  }
) => {
  context.commit(types.SET_WOZ_PROFILE_SAVE_MODAL_VISIBILITY, payload)
}

const commitAlert = (
  context: ActionContext<WOZAgentsState, RootState>,
  key: string
): boolean => {
  context.commit(types.SET_WOZ_ACTIVE_PROFILE_ALERTS, {
    open: true,
    title: i18n.t(`woz.agents.activateProfileAlert.${key}.title`),
    description: i18n.t(`woz.agents.activateProfileAlert.${key}.description`)
  })

  return false
}

export const validateCanActivateProfile = (
  context: ActionContext<WOZAgentsState, RootState>,
  payload: {
    contents: any
    agent?: WozAgent
  }
) => {
  const { contents, agent } = payload

  const { requiredFieldsFilled } = context.getters

  const isEmptyContent = !contents.length

  const hasMissingRequiredFields = !requiredFieldsFilled(agent)

  const hasNoActiveContent = !contents.some(
    (item: any) => item.active && item.status === DOCUMENT_STATUS.COMPLETED
  )

  if (isEmptyContent) {
    return commitAlert(context, 'emptyContent')
  }

  if (hasMissingRequiredFields) {
    return commitAlert(context, 'requiredFields')
  }

  if (hasNoActiveContent) {
    return commitAlert(context, 'withOutActiveContents')
  }

  return true
}

export const closeActiveProfileAlerts = (
  context: ActionContext<WOZAgentsState, RootState>
) => {
  context.commit(types.SET_WOZ_ACTIVE_PROFILE_ALERTS, {
    open: false,
    title: '',
    description: ''
  })
}
