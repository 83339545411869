import botRoutes from './Bot/routes'
import adsRoutes from './Campaigns/routes'
import octaflowRoutes from './OctaFlow/routes'

export default {
  path: '/automation-studio',
  name: 'automation-studio',
  redirect: '/automation-studio/bot/whatsapp',
  component: () => import('@/views/AutomationStudio.vue'),
  children: [botRoutes, octaflowRoutes, adsRoutes]
}
