import axios from 'axios'

import { headers } from '@octadesk-tech/services'

import { OPEN_CHAT_MESSAGE_LIMIT } from '@/modules/Chat/helpers/utils'
import { getChatClient } from '@/modules/Chat/services/http'

import { language } from '@/common/i18n'

import { ChatService } from '@/common/services/chat'

const chatService = new ChatService()

const CancelToken = axios.CancelToken

const AppSubDomain = headers.getAuthorizedHeaders()?.headers?.AppSubDomain

export const getChat = chatKey =>
  getChatClient()
    .then(client =>
      client.get(`/rooms/${chatKey}/page`, {
        headers: {
          ...headers.getAuthorizedHeaders()?.headers,
          Culture: language
        }
      })
    )
    .then(response => response.data)

export const getRoomLastUpdate = roomKey => {
  return getChatClient()
    .then(client =>
      client.head(`/rooms/${roomKey}`, {
        params: {
          sd: AppSubDomain
        },
        headers: {
          ...headers.getAuthorizedHeaders()?.headers,
          Culture: language
        }
      })
    )
    .then(response => response.headers['last-modified'])
}

export const viewChat = chatNumber => {
  return getChatClient()
    .then(client =>
      client.get(`rooms/view/${chatNumber}`, {
        headers: {
          ...headers.getAuthorizedHeaders()?.headers,
          Culture: language
        }
      })
    )
    .then(response => response.data)
}

let openChatCancelToken

export const fetchPaginatedChat = (
  chatKey,
  page = 1,
  limit = OPEN_CHAT_MESSAGE_LIMIT
) => {
  if (openChatCancelToken) {
    openChatCancelToken.cancel()
  }

  openChatCancelToken = CancelToken.source()

  return getChatClient()
    .then(client =>
      client.get(`/rooms/${chatKey}/open/page?page=${page}&limit=${limit}`, {
        cancelToken: openChatCancelToken.token,
        headers: {
          ...headers.getAuthorizedHeaders()?.headers,
          Culture: language
        }
      })
    )
    .then(response => {
      return response.data
    })
}

export const setAgent = (chatKey, { agent, group }) => {
  const params = {}

  if (group) {
    params.idGroup = group.id
  }

  return getChatClient().then(client =>
    client({
      method: 'PUT',
      url: `rooms/${chatKey}/agent/${agent.id}`,
      params,
      headers: {
        ...headers.getAuthorizedHeaders()?.headers,
        Culture: language
      }
    })
  )
}

export const setAgentBatch = payload => {
  return getChatClient()
    .then(client =>
      client.put('rooms/bulk/agent', payload, {
        headers: {
          ...headers.getAuthorizedHeaders()?.headers,
          Culture: language
        }
      })
    )
    .then(response => response.data)
}

export const setGroup = (chatKey, idGroup) =>
  getChatClient().then(client =>
    client({
      method: 'PUT',
      url: `rooms/${chatKey}/group/${idGroup}`,
      headers: {
        ...headers.getAuthorizedHeaders()?.headers,
        Culture: language
      }
    })
  )

export const setGroupBatch = payload =>
  getChatClient()
    .then(client =>
      client.put('rooms/bulk/group', payload, {
        headers: {
          ...headers.getAuthorizedHeaders()?.headers,
          Culture: language
        }
      })
    )
    .then(response => response.data)

export const closeConversation = (chatKey, agent, removeReminderId) =>
  getChatClient()
    .then(client =>
      client.put(
        `rooms/${chatKey}/close`,
        { user: agent, removeReminderId },
        {
          headers: {
            ...headers.getAuthorizedHeaders()?.headers,
            Culture: language
          }
        }
      )
    )
    .then(response => response.data)

export const closeConversationBatch = payload =>
  getChatClient()
    .then(client =>
      client.put(`rooms/bulk/close`, payload, {
        headers: {
          ...headers.getAuthorizedHeaders()?.headers,
          Culture: language
        }
      })
    )
    .then(response => response.data)

export const createTicket = chatKey => {
  return getChatClient().then(client =>
    client({
      method: 'post',
      url: `rooms/${chatKey}/ticket`,
      headers: {
        ...headers.getAuthorizedHeaders()?.headers,
        Culture: language
      }
    })
  )
}

export const sendMessage = (chatKey, message) =>
  getChatClient()
    .then(client =>
      client.post(`/rooms/${chatKey}/messages`, message, {
        headers: {
          ...headers.getAuthorizedHeaders()?.headers,
          Culture: language
        }
      })
    )
    .then(res => res.data)
    .catch(err => {
      throw err?.response?.data || err
    })

let getUserChatsCancelToken

export const getUserChats = (id, page) => {
  if (!id) {
    return
  }

  if (getUserChatsCancelToken) {
    getUserChatsCancelToken.cancel()
  }

  getUserChatsCancelToken = CancelToken.source()

  return getChatClient()
    .then(client =>
      client.get(`/rooms/user/${id}?page=${page}`, {
        cancelToken: getUserChatsCancelToken.token,
        headers: {
          ...headers.getAuthorizedHeaders()?.headers,
          Culture: language
        }
      })
    )
    .then(res => res.data)
    .catch(error => {
      if (axios.isCancel(error)) {
        console.warn('Request canceled in getUserChats:', error.message)
      } else {
        console.error('[ERROR] in getUserChats:', error)
      }
    })
}

let getUserOpenedChatCancelToken

export const getUserOpenedChat = key => {
  if (getUserOpenedChatCancelToken) {
    getUserOpenedChatCancelToken.cancel()
  }

  getUserOpenedChatCancelToken = CancelToken.source()

  return getChatClient()
    .then(client =>
      client.get(`rooms/opened/user/${key}`, {
        cancelToken: getUserOpenedChatCancelToken.token,
        headers: {
          ...headers.getAuthorizedHeaders()?.headers,
          Culture: language
        }
      })
    )
    .then(res => res.data)
    .catch(error => {
      if (axios.isCancel(error)) {
        console.warn('Request canceled in getUserOpenedChat:', error.message)
      } else {
        console.error('[ERROR] in getUserOpenedChat:', error)
      }
    })
}

export const createUserChatByNumbers = (userNumber, agentNumber, chat = {}) => {
  Object.assign(chat, {
    clientTimeZone: new Date().getTimezoneOffset()
  })

  return getChatClient()
    .then(client =>
      client.post(`rooms/opened/user/${userNumber}/${agentNumber}`, chat, {
        headers: {
          ...headers.getAuthorizedHeaders()?.headers,
          Culture: language
        }
      })
    )
    .then(response => ({ status: response.status, data: response.data }))
    .catch(err => ({ status: err.response.status, data: err.response.data }))
}

export const setMessagesReaded = chatKey =>
  getChatClient().then(client =>
    client({
      method: 'PUT',
      url: `rooms/${chatKey}/messages/read`,
      headers: {
        ...headers.getAuthorizedHeaders()?.headers,
        Culture: language
      }
    })
  )

export const setTyping = (roomKey, type, user) =>
  getChatClient().then(client =>
    client.post(`rooms/${roomKey}/typing/${type}`, user, {
      headers: {
        ...headers.getAuthorizedHeaders()?.headers,
        Culture: language
      }
    })
  )

export const updateCreatedBy = (roomKey, createdBy) =>
  getChatClient()
    .then(client =>
      client.put(`rooms/${roomKey}/customer`, createdBy, {
        headers: {
          ...headers.getAuthorizedHeaders()?.headers,
          Culture: language
        }
      })
    )
    .then(res => res.data)

export const getCounter = (status, search, page, byPeriod, filter) => {
  const query = {
    status,
    search,
    page,
    ...byPeriod,
    ...filter
  }

  return chatService.getChatConversationCount(query).then(res => res.data)
}

export const getWaitingCount = () => {
  return getChatClient()
    .then(client =>
      client.get('rooms/waiting-count', {
        headers: {
          ...headers.getAuthorizedHeaders()?.headers,
          Culture: language
        }
      })
    )
    .then(res => res.data)
}

export const createChat = async (payload = {}) => {
  Object.assign(payload, {
    clientTimeZone: new Date().getTimezoneOffset()
  })

  return getChatClient()
    .then(client =>
      client.post('rooms', payload, {
        headers: {
          ...headers.getAuthorizedHeaders()?.headers,
          Culture: language
        }
      })
    )
    .then(response => response.data)
    .catch(err => err?.response?.data)
}

export const getAllChatsCount = async () => {
  const filter = { status: [0, 1, 2, 3, 4, 5, 6, 7], search: '', page: 1 }

  return chatService.getChatConversationCount(filter).then(res => res.data)
}

export const createRoomFromAnother = async (roomKey, idAgent) =>
  getChatClient()
    .then(client =>
      client.post(
        `rooms/${roomKey}/new`,
        { idAgent },
        {
          headers: {
            ...headers.getAuthorizedHeaders()?.headers,
            Culture: language
          }
        }
      )
    )
    .then(res => res.data)

export const changeStatus = async (roomKey, status) =>
  getChatClient()
    .then(client =>
      client.put(
        `rooms/${roomKey}/status`,
        { status },
        {
          headers: {
            ...headers.getAuthorizedHeaders()?.headers,
            Culture: language
          }
        }
      )
    )
    .then(res => res.data)

export const getUserLastChat = id =>
  getChatClient()
    .then(client =>
      client.post(
        `rooms/filter`,
        {
          'createdBy._id': id,
          channel: 'whatsapp',
          sort: {
            property: 'created',
            direction: 'desc'
          },
          limit: 1
        },
        {
          headers: {
            ...headers.getAuthorizedHeaders()?.headers,
            Culture: language
          }
        }
      )
    )
    .then(res => res.data)

export const updateCustomer = customer =>
  getChatClient()
    .then(client =>
      client.put(
        `rooms/customer`,
        { customer },
        {
          headers: {
            ...headers.getAuthorizedHeaders()?.headers,
            Culture: language
          }
        }
      )
    )
    .then(response => response.data)

let paginatedMessagesRequestCancelToken = null
export const fetchPaginatedMessages = (
  chatKey,
  page = 1,
  limit = OPEN_CHAT_MESSAGE_LIMIT
) => {
  paginatedMessagesRequestCancelToken = CancelToken.source()

  return getChatClient()
    .then(client =>
      client.get(
        `/rooms/${chatKey}/messages/paginated?page=${page}&limit=${limit}`,
        {
          cancelToken: paginatedMessagesRequestCancelToken.token,
          headers: {
            ...headers.getAuthorizedHeaders()?.headers,
            Culture: language
          }
        }
      )
    )
    .then(response => response.data)
}

export const cancelPaginatedMessagesRequest = () => {
  if (paginatedMessagesRequestCancelToken)
    paginatedMessagesRequestCancelToken.cancel()
}

let quotedMessagePageCancelToken = null

export const fetchQuotedMessagePage = async (
  chatKey,
  quotedRoomKey,
  limit = OPEN_CHAT_MESSAGE_LIMIT
) => {
  quotedMessagePageCancelToken = CancelToken.source()

  return getChatClient()
    .then(client =>
      client.get(
        `/rooms/${chatKey}/quoted-message/page?quotedRoomKey=${quotedRoomKey}&limit=${limit}`,
        {
          cancelToken: quotedMessagePageCancelToken.token,
          headers: {
            ...headers.getAuthorizedHeaders()?.headers,
            Culture: language
          }
        }
      )
    )
    .then(response => response.data)
}

export const cancelQuotedMessagePageRequest = () => {
  if (quotedMessagePageCancelToken) quotedMessagePageCancelToken.cancel()
}

export const logData = data =>
  getChatClient()
    .then(client =>
      client.post(`widget/log`, data, {
        headers: {
          ...headers.getAuthorizedHeaders()?.headers,
          Culture: language
        }
      })
    )
    .then(response => response.data)

export const fetchConversationAgentPermissions = async chatKey =>
  getChatClient()
    .then(client =>
      client.get(`/rooms/${chatKey}/permissions`, {
        headers: {
          ...headers.getAuthorizedHeaders()?.headers,
          Culture: language
        }
      })
    )
    .then(response => response.data)

export const fetchBulkConversationsAgentPermissions = async roomKeys =>
  getChatClient()
    .then(client =>
      client.post(
        `/rooms/permissions/bulk`,
        { roomKeys },
        {
          headers: {
            ...headers.getAuthorizedHeaders()?.headers,
            Culture: language
          }
        }
      )
    )
    .then(response => response.data)

export const getUserChat = async roomKey =>
  getChatClient()
    .then(client =>
      client.get(`/rooms/${roomKey}/print`, {
        headers: {
          ...headers.getAuthorizedHeaders()?.headers,
          Culture: language
        }
      })
    )
    .then(response => response.data)

export const fetchAskMe = async (roomKey, payload) =>
  getChatClient().then(client =>
    client.put(`/rooms/${roomKey}/ask-me`, payload, {
      headers: {
        ...headers.getAuthorizedHeaders()?.headers,
        Culture: language
      }
    })
  )

export const updateCustomFields = async (roomKey, payload) =>
  getChatClient().then(client =>
    client.put(`/rooms/${roomKey}/custom-fields`, payload, {
      headers: {
        ...headers.getAuthorizedHeaders()?.headers,
        Culture: language
      }
    })
  )

export const fetchConvertedMp3Audio = async url =>
  getChatClient().then(client =>
    client
      .get(url, {
        headers: {
          ...headers.getAuthorizedHeaders()?.headers,
          Culture: language
        },
        responseType: 'blob'
      })
      .then(
        response =>
          new Blob([response.data], {
            type: 'audio/mp3'
          })
      )
  )
