import { CustomFieldState } from './interfaces'

export const getCustomFields: (state: CustomFieldState) => any = state =>
  state.customFields

export const organizationCustomFields: (
  state: CustomFieldState
) => any = state => state.organizationCustomFields

export const tableData = (state: CustomFieldState) => state.tableData

export const customFieldType = (state: CustomFieldState) =>
  state.customFieldType

export const newOrderItem = (state: CustomFieldState) => state.newItemOrder

export const conversationCustomFields: (
  state: CustomFieldState
) => any = state => state.conversationCustomFields
