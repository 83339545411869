<template>
  <octa-theme-provider class="h-full w-full">
    <OctaSmartBannerToApp v-if="fetched" />

    <router-view id="main-container" v-if="fetched" />

    <octa-loading v-if="showLoader" fullscreen :message="textLoader" />

    <OctaDialogControl />

    <GlobalAlerts v-if="globalAlerts?.length" />

    <ReloadAlert v-if="showReloadPageAlert" />
    <ShouldLogoutAlert v-if="showShouldLogoutAlert" />
  </octa-theme-provider>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'

  import { OctaThemeProvider } from '@octadesk-tech/web-components'

  import { OctaDialogControl } from '@/modules/shareds'
  import OctaSmartBannerToApp from '@/modules/shareds/components/OctaSmartBannerToApp/OctaSmartBannerToApp.vue'

  import OctaLoading from './components/general/OctaLoading.vue'
  import ShouldLogoutAlert from './components/LogoutAlert.vue'
  import ReloadAlert from './components/ReloadAlert.vue' // this import cannot be asynchronous because if it is the expected behavior it will not be shown
  import { SignupUserTypes } from './helpers/enums/signup-user-types'
  import { setCookie } from './helpers/utils/cookie'
  import {
    getParameterByName,
    removeQueryString
  } from './helpers/utils/get-parameter-by-name'
  import { languages } from './helpers/utils/languages'
  import { debouncedHandleTicketRoutes } from './helpers/utils/ticket-embed-routes.util'
  import { EventTrackingService } from './services/event-tracking.service'

  export default {
    metaInfo() {
      return {
        title: 'Octadesk',
        meta: [
          {
            vmid: 'description',
            name: 'description',
            content: 'Faça o login do seu Octadesk aqui.'
          },
          { name: 'robots', content: 'index,follow' }
        ]
      }
    },
    components: {
      OctaLoading,
      OctaDialogControl,
      OctaThemeProvider,
      GlobalAlerts: () => import('./components/GlobalAlerts.vue'),
      ReloadAlert,
      ShouldLogoutAlert,
      OctaSmartBannerToApp
    },
    data() {
      return {
        fetched: false
      }
    },
    computed: {
      ...mapGetters([
        'lang',
        'showLoader',
        'textLoader',
        'globalAlerts',
        'showReloadPageAlert',
        'showShouldLogoutAlert',
        'isMoskit'
      ])
    },
    async created() {
      this.setShowLoader(true)

      await this.handleSetLanguage()

      await this.checkFromLegacy()

      EventTrackingService.listenEvents()

      await this.handleCheckAuthState()

      this.setShowLoader(false)

      this.newOctaTicketAddEventListner()
      if (!localStorage.getItem('tabOpen')) {
        localStorage.setItem('tabOpen', 'true')
      }
    },
    methods: {
      ...mapActions({
        setShowLoader: 'showLoader',
        setLanguage: 'setLanguage',
        setTextLoader: 'setTextLoader',
        setLoginType: 'setLoginType',
        setLegacyLoginInfo: 'setLegacyLoginInfo',
        handleLogin: 'handleLogin',
        checkAuthState: 'checkAuthState',
        getWhatsAppOfficialBalance: 'getWhatsAppOfficialBalance'
      }),
      getLang(langParam, langStore) {
        const lng = languages.find(
          l => l.shortName === langParam?.split('-')[0]
        )

        return langParam && lng ? lng.fullName : langStore
      },
      async resetCredentials() {
        await this.$store.dispatch('clearCredentials')
      },
      async handleSetLanguage() {
        const { name: langStore } = this.lang

        const langParam = getParameterByName('lng')

        try {
          await this.setLanguage({
            name: this.getLang(langParam, langStore)
          })

          this.setTextLoader(this.$t('general.loading'))

          this.fetched = true
        } catch (ex) {
          console.error('Error on setLanguage', ex)
        }
      },
      async checkFromLegacy() {
        try {
          const token = getParameterByName('legacytoken')
          if (token) {
            this.setTextLoader(this.$t('general.loadingNewExp'))

            await this.resetCredentials()

            setCookie('Vuetiful', 'true')

            await this.setLoginType(SignupUserTypes.Legacy)

            await this.setLegacyLoginInfo(token)

            await this.handleLogin()

            window.location.href = removeQueryString()
          }
        } catch (ex) {
          await this.resetCredentials()

          this.$router.push({ name: 'Login' }).catch(() => {})

          console.error('Error on checkFromLegacy:', ex)
        }
      },
      async handleCheckAuthState() {
        try {
          const state = await this.checkAuthState(this.$route.path)
          const isMoskitWhitelabel =
            this.isMoskit &&
            window.location.href.includes('whitelabel.octadesk.com')

          if (state && !isMoskitWhitelabel) {
            this.getWhatsAppOfficialBalance()

            this.$router.push({ name: 'Private' }).catch(() => {})
          } else {
            this.$router.push({ name: 'Public' }).catch(() => {})
          }
        } catch (error) {
          console.error('Error on checkAuthState: ', error)
        }
      },
      newOctaTicketAddEventListner() {
        window.addEventListener(
          'message',
          event => {
            if (
              event?.data?.name !== 'octadesk_routeChangedSuccess' ||
              event?.data?.routeData?.id === 'new'
            )
              return

            debouncedHandleTicketRoutes(event)
          },
          false
        )
      }
    }
  }
</script>
