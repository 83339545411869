import { BillingStates, Items } from './types'

export const summary = (state: BillingStates) => state

export const features = ({ items }: BillingStates) => items

export const plan = ({ plan }: BillingStates) => plan

export const currentPaymentValue = ({ nextPayment }: BillingStates) =>
  nextPayment.total

export const hasPaymentMethod = (state: BillingStates) => {
  return summary(state).paymentMethod
}

const findFeature = (items: Items[], code: string): Items | object =>
  items?.find(item => item?.featureCode === code) || {}

export const userFeature = (state: BillingStates) =>
  findFeature(state.items, 'user')

export const ticketFeature = (state: BillingStates) =>
  findFeature(state.items, 'new-octa-ticket')

export const whatsappFeature = (state: BillingStates) =>
  findFeature(state.items, 'whatsapp-api')

export const additionalsFeature = (state: BillingStates) =>
  state.items.filter(item => {
    return item.featureIsAddOn && item.featureQty > 0
  })

export const proactiveMessageFeature = (state: BillingStates) =>
  findFeature(state.items, 'whatsapp-api-pkg-msg-single-250')

export const hasLimitedUsers = (state: BillingStates) => {
  const user = userFeature(state) as Items
  return user?.featureMaxLimit === user?.featureBase
}

export const isMonthlyPlan = (state: BillingStates) => {
  const planCode = state.plan.code
  return planCode.includes('mensal')
}

export const bulkSelectedUsers = (state: BillingStates) => {
  return state.bulkSelectedUsers
}
