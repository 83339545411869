import { ActionContext } from 'vuex'

import {
  getDocument,
  getContentGaps,
  markAsResolved
} from '@/modules/Woz/content/content.service'

import { RootState } from '@/store/interfaces'

import { WozContentState } from './interfaces'
import * as types from './mutation-types'

export const getWozDocument = (
  _: ActionContext<WozContentState, RootState>,
  id: string
) => {
  return getDocument(id).catch(ex =>
    console.error('Error on get document', { ex, id })
  )
}

export const searchContentGaps = async (
  context: ActionContext<WozContentState, RootState>,
  query: { search: string; page: number; sortDirection: string | undefined }
) => {
  try {
    context.commit(types.SET_WOZ_CONTENT_GAPS_LIST_SEARCHING)

    const response = await getContentGaps(
      query.search,
      query.page,
      query.sortDirection
    )

    context.commit(types.SET_WOZ_CONTENT_GAPS_LIST, response)
  } catch (err) {
    throw new Error('Error on get content gaps', { cause: err })
  }
}

export const clearContentGaps = (
  context: ActionContext<WozContentState, RootState>
) => {
  context.commit(types.CLEAR_WOZ_CONTENT_GAPS_LIST)
}

export const markContentGapAsResolved = async (
  _: ActionContext<WozContentState, RootState>,
  keys: [{ key: string; subKey: string }]
) => {
  try {
    await markAsResolved(keys)
  } catch (err) {
    throw new Error('Error on mark content gap as resolved', { cause: err })
  }
}

export const setWozContentAgentDocumentsToDelete = (
  context: ActionContext<WozContentState, RootState>,
  ids: string[]
) => {
  context.commit(types.SET_WOZ_CONTENT_AGENT_DOCUMENTS_TO_DELETE, ids)

  const { agentDocumentsToLink } = context.getters

  const updatedDocumentsToLink = agentDocumentsToLink.filter(
    (id: string) => !ids.includes(id)
  )

  if (
    updatedDocumentsToLink.length !== agentDocumentsToLink.length ||
    !updatedDocumentsToLink.every(
      (id: string, index: number) => id === agentDocumentsToLink[index]
    )
  ) {
    context.dispatch(
      'setWozContentAgentDocumentsToLink',
      updatedDocumentsToLink
    )
  }
}

export const setWozContentAgentDocumentsToLink = (
  context: ActionContext<WozContentState, RootState>,
  ids: string[]
) => {
  context.commit(types.SET_WOZ_CONTENT_AGENT_DOCUMENTS_TO_LINK, ids)

  const { agentDocumentsToDelete } = context.getters

  const updatedDocumentsToDelete = agentDocumentsToDelete.filter(
    (id: string) => !ids.includes(id)
  )

  if (
    updatedDocumentsToDelete.length !== agentDocumentsToDelete.length ||
    !updatedDocumentsToDelete.every(
      (id: string, index: number) => id === agentDocumentsToDelete[index]
    )
  ) {
    context.dispatch(
      'setWozContentAgentDocumentsToDelete',
      updatedDocumentsToDelete
    )
  }
}
