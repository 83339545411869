import { WozContentState } from './interfaces'

const state: WozContentState = {
  agent: {
    documentsToDelete: [],
    documentsToLink: []
  },
  contentGapsList: {
    data: undefined,
    pagination: {
      page: 0,
      totalPages: 0,
      totalItems: 0,
      from: 0,
      to: 0
    }
  }
}

export default state
