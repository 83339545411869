<template>
  <div>
    <octa-alert variant="info" :class="$style['logout-alert']">
      <template #body>
        <div class="w-full flex flex-col gap-2">
          <div class="w-full flex flex-col p-2 gap-[16px]">
            <octa-title
              tag="h5"
              :class="$style['title']"
              :title="$t('logoutAlert.title')"
            />

            <octa-description :class="$style['description']">
              <span>
                {{ $t('logoutAlert.description') }}
              </span>
            </octa-description>

            <octa-description :class="$style['description']">
              <span v-if="logoutLoader">
                {{ $t('logoutAlert.countDown.updateNow') }}
              </span>

              <span v-else>
                {{ $t('logoutAlert.countDown.updateIn', { countDown }) }}
              </span>
            </octa-description>
          </div>

          <div class="mt-2 w-full flex justify-end items-center gap-2">
            <octa-btn link theme="base" @click="handleShowConfirmModal">
              {{ $t('logoutAlert.buttons.snooze') }}
            </octa-btn>

            <octa-btn
              theme="primary"
              :disabled="logoutLoader"
              :loading="logoutLoader"
              @click="handleLogout"
            >
              {{ $t('logoutAlert.buttons.updateNow') }}
            </octa-btn>
          </div>
        </div>
      </template>
    </octa-alert>

    <octa-modal
      :open="showConfirmModal"
      @dismiss="handleCloseConfirmModal"
      :title="$t('logoutAlert.modal.title')"
    >
      <template #body>
        <octa-description>
          {{ $t('logoutAlert.modal.description.alert') }}
          <br />
          <br />

          <span v-if="logoutLoader">
            {{ $t('logoutAlert.modal.description.updateNow') }}
          </span>

          <span v-else>
            {{ $t('logoutAlert.modal.description.updateIn', { countDown }) }}
          </span>
        </octa-description>
      </template>

      <template #footer>
        <octa-btn link theme="base" @click="handleSnooze">
          {{ $t('logoutAlert.modal.buttons.snooze') }}
        </octa-btn>

        <octa-btn
          :disabled="logoutLoader"
          :loading="logoutLoader"
          @click="handleLogout"
        >
          {{ $t('logoutAlert.modal.buttons.updateNow') }}
        </octa-btn>
      </template>
    </octa-modal>
  </div>
</template>

<script setup lang="ts">
  import { ref, watch, onMounted } from 'vue'

  import {
    OctaAlert,
    OctaDescription,
    OctaBtn,
    OctaModal,
    OctaTitle
  } from '@octadesk-tech/web-components'

  import { useStore } from '@/store'

  const store = useStore()
  const oneMinute = 60
  const countDown = ref(0)
  const showConfirmModal = ref(false)
  const logoutLoader = ref(false)

  const handleLogout = () => {
    logoutLoader.value = true
    store.dispatch('logout', {
      logoutReason: 'agentDisconnectedAfterProfileUpdate'
    })
  }

  const handleShowConfirmModal = () => {
    showConfirmModal.value = true
  }

  const handleCloseConfirmModal = () => {
    showConfirmModal.value = false
  }

  const handleSnooze = () => {
    countDown.value = oneMinute
    showConfirmModal.value = false
  }

  const setCountDown = () => {
    countDown.value = oneMinute

    const downloadTimer = setInterval(() => {
      if (countDown.value <= 0) {
        clearInterval(downloadTimer)
      }
      countDown.value -= 1
    }, 1000)
  }

  watch(countDown, value => {
    if (value <= 0) {
      handleLogout()
    }
  })

  onMounted(() => {
    setCountDown()
  })
</script>

<style lang="scss" module>
  .logout-alert {
    @apply absolute z-50 top-[32px] right-[32px] bg-white border-none max-w-[400px] rounded-2xl p-6;
    box-shadow: 0 0 15px rgba(48, 51, 66, 0.2);

    .title {
      @apply text-2xl text-blueGray-800;
    }

    .description {
      @apply text-base text-blueGray-600 font-normal;
    }
  }
</style>
