import { overwriteObject } from '@/modules/support'

import * as types from './mutation-types'
import { BillingStates } from './types'

export default {
  [types.LOAD_SUMMARY]: (state: BillingStates, payload: BillingStates) => {
    overwriteObject(state, payload, false)
  },
  [types.SET_BULK_SELECTED_AGENTS]: (state: any, payload: any) => {
    state.bulkSelectedUsers = payload
  }
}
