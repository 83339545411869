import { PaginatedData } from '@/store/interfaces/pagination'

import { WozContentState, WozContentGaps } from './interfaces'
import * as types from './mutation-types'

export default {
  [types.SET_WOZ_CONTENT_GAPS_LIST](
    state: WozContentState,
    contentGapsList: PaginatedData<WozContentGaps>
  ) {
    state.contentGapsList = contentGapsList
  },
  [types.SET_WOZ_CONTENT_GAPS_LIST_SEARCHING](state: WozContentState) {
    state.contentGapsList = { ...state.contentGapsList, data: undefined }
  },
  [types.CLEAR_WOZ_CONTENT_GAPS_LIST](state: WozContentState) {
    state.contentGapsList = {
      ...state.contentGapsList,
      pagination: { page: 0, totalPages: 0, totalItems: 0, from: 0, to: 0 },
      data: undefined
    }
  },
  [types.SET_WOZ_CONTENT_AGENT_DOCUMENTS_TO_DELETE](
    state: WozContentState,
    ids: string[]
  ) {
    state.agent.documentsToDelete = ids
  },
  [types.SET_WOZ_CONTENT_AGENT_DOCUMENTS_TO_LINK](
    state: WozContentState,
    ids: string[]
  ) {
    state.agent.documentsToLink = ids
  }
}
