import Vue, { Component, ComponentOptions, CreateElement, VNode } from 'vue'

import GeneralPurposeDialog from './GeneralPurposeDialog.vue'
import OctaDialog from './OctaDialog.vue'

type DialogState = {
  show: boolean
  component: Component | undefined
  options?: DialogOptions & object
}

type DialogOptions<P = object> = {
  componentProps?: P
  componentEvents?: object
  persistent?: boolean
  maxWidth?: number
  closeIcon?: boolean
  hooks?: DialogHooks
}

type DialogHooks = {
  hookOk?: (args?: unknown) => void
  hookCancel?: (args?: unknown) => void
  hookDismiss?: (args?: unknown) => void
}

const dialogState = Vue.observable<DialogState>({
  show: false,
  component: undefined,
  options: {}
})

const close = () => {
  dialogState.show = false
  dialogState.component = undefined
  dialogState.options = {}
}

const show = <T extends ComponentOptions<Vue>>(
  component: T,
  options?: DialogOptions<{ [K in keyof T['props']]: T['props'][K] }>
) => {
  dialogState.component = component
  dialogState.options = {
    ...options
  }
  dialogState.options.closeIcon = options?.closeIcon
  dialogState.show = true
}

const dialog = (props: {
  title: string
  errorType?: string
  message: string
  primaryAction: string
  secondaryAction?: string
  primaryHook: (args?: unknown) => void
  secondaryHook?: () => void
  cancelButton?: boolean
  persistent?: boolean
  closeIcon?: boolean
}) => {
  show(GeneralPurposeDialog as any, {
    componentProps: props,
    persistent: props.persistent,
    closeIcon: props.closeIcon
  })
}

export const useOctaDialog = () => ({
  dialog,
  show,
  close
})

const dialogControl: Component = {
  name: 'OctaDialogControl',
  functional: true,
  render(h: CreateElement): VNode | VNode[] {
    const hookDismiss = (args: any) => {
      dialogState.options?.hooks?.hookDismiss &&
        dialogState.options?.hooks?.hookDismiss(args)
      close()
    }

    const hookCancel = (args: any) => {
      dialogState.options?.hooks?.hookCancel &&
        dialogState.options?.hooks?.hookCancel(args)
      close()
    }

    return h(
      OctaDialog,
      {
        props: {
          value: dialogState.show,
          persistent: dialogState.options?.persistent,
          maxWidth: dialogState.options?.maxWidth,
          closeIcon: dialogState.options?.closeIcon
        },
        on: {
          input: (v: boolean) => (dialogState.show = v)
        }
      },
      [
        dialogState.show
          ? h(dialogState.component, {
              on: {
                ...dialogState.options?.componentEvents
              },
              props: {
                ...dialogState?.options?.componentProps,
                ...dialogState.options?.hooks,
                hookCancel,
                hookDismiss
              }
            })
          : ''
      ]
    )
  }
}

export default dialogControl
