export const SET_WOZ_AGENTS = 'SET_WOZ_AGENTS'

export const SET_WOZ_COPILOT_AGENT = 'SET_WOZ_COPILOT_AGENT'

export const SET_WOZ_AGENTS_LOADING = 'SET_WOZ_AGENTS_LOADING'

export const SET_WOZ_PROFILE_INITIAL_CURRENT_AGENT =
  'SET_WOZ_PROFILE_INITIAL_CURRENT_AGENT'

export const SET_WOZ_CURRENT_AGENT = 'SET_WOZ_CURRENT_AGENT'

export const SET_WOZ_PROFILE_CURRENT_TAB = 'SET_WOZ_PROFILE_CURRENT_TAB'

export const SET_WOZ_PROFILE_SAVE_MODAL_VISIBILITY =
  'SET_WOZ_PROFILE_SAVE_MODAL_VISIBILITY'

export const SET_WOZ_ACTIVE_PROFILE_ALERTS = 'SET_WOZ_ACTIVE_PROFILE_ALERTS'
