import { AxiosInstance } from 'axios'

import { services, headers } from '@octadesk-tech/services'

import { InactivityChannel } from './types'

export class InactivityEventsService {
  async getClient(): Promise<AxiosInstance> {
    return (await services.createClient('widgetBff')) as AxiosInstance
  }

  async getEvents() {
    return this.getClient().then(client =>
      client.get('/events', headers.getAuthorizedHeaders())
    )
  }

  async createEvents(events: InactivityChannel[]) {
    return this.getClient().then(client =>
      client.post('/events', { events }, headers.getAuthorizedHeaders())
    )
  }

  async updateEvents(events: InactivityChannel[]) {
    return this.getClient().then(client =>
      client.put('/events', { events }, headers.getAuthorizedHeaders())
    )
  }
}
