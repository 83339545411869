export const SET_AUTH = 'SET_AUTH'
export const SET_TENANTS = 'SET_TENANTS'
export const SET_REDIRECT_AFTER_LOGIN = 'SET_REDIRECT_AFTER_LOGIN'
export const SET_KEEP_CONNECTED = 'KEEP_CONNECTED'
export const SET_SUBDOMAIN_STATUS = 'SET_SUBDOMAIN_STATUS'
export const SET_USER_LOGGED = 'SET_USER_LOGGED'
export const SET_USER_TOKEN = 'SET_USER_TOKEN'
export const SET_ID_OCTADESK = 'SET_ID_OCTADESK'
export const SET_PREV_LOGIN_TYPE = 'SET_PREV_LOGIN_TYPE'
export const SET_LOGIN_TYPE = 'SET_LOGIN_TYPE'
export const SET_EMAIL = 'SET_EMAIL'
export const SET_EMAIL_LOGIN_INFO = 'SET_EMAIL_LOGIN_INFO'
export const SET_GOOGLE_TOKEN = 'SET_GOOGLE_TOKEN'
export const SET_LEGACY_LOGIN_INFO = 'SET_LEGACY_LOGIN_INFO'
export const SET_TENANT_ID = 'SET_TENANT_ID'
export const SET_TENANT_NAME = 'SET_TENANT_NAME'
export const SET_COMPANY_MODEL = 'SET_COMPANY_MODEL'
export const SET_CONNECTION_STATUS = 'SET_CONNECTION_STATUS'
export const SET_TRIAL_OVER_MODAL = 'SET_TRIAL_OVER_MODAL'
export const SET_PENDING_PAYMENT_MODAL = 'SET_PENDING_PAYMENT_MODAL'
export const SET_CURRENT_AGENT_APPS_IDS = 'SET_CURRENT_AGENT_APPS_IDS'
export const SET_TENANT_CONFIGS = 'SET_TENANT_CONFIGS'
export const SET_MIGRATED_ENVIRONMENTED = 'SET_MIGRATED_ENVIRONMENTED'
export const SET_PASSWORD_EXPIRED = 'SET_PASSWORD_EXPIRED'
export const SET_SSO_TENANTS = 'SET_SSO_TENANTS'
export const SET_SHOULD_LOGOUT_ALERT = 'SET_SHOULD_LOGOUT_ALERT'
export const SET_TWO_FACTOR_AUTH_PENDING = 'SET_TWO_FACTOR_AUTH_PENDING'
