export const SET_WOZ_CONTENT_GAPS_LIST = 'SET_WOZ_CONTENT_GAPS_LIST'

export const SET_WOZ_CONTENT_GAPS_LIST_SEARCHING =
  'SET_WOZ_CONTENT_GAPS_LIST_SEARCHING'

export const CLEAR_WOZ_CONTENT_GAPS_LIST = 'CLEAR_WOZ_CONTENT_GAPS_LIST'

export const SET_WOZ_CONTENT_AGENT_DOCUMENTS_TO_DELETE =
  'SET_WOZ_CONTENT_AGENT_DOCUMENTS_TO_DELETE'

export const SET_WOZ_CONTENT_AGENT_DOCUMENTS_TO_LINK =
  'SET_WOZ_CONTENT_AGENT_DOCUMENTS_TO_LINK'
