import { ChatConnectionStatus } from '@/common/helpers/enums/chat-connection-status'
import { SubDomainStatus } from '@/common/helpers/interfaces/subdomain-status'
import { UserLogged } from '@/common/helpers/interfaces/user-logged'

import { AuthState, TenantConfigs, SSOTenant } from '@/store/interfaces'

import * as types from './mutations-types'

export default {
  [types.SET_AUTH](state: any, auth: AuthState) {
    state.access_token = auth.access_token
    state.octaAuthenticated = auth.octaAuthenticated
    state.roles = auth.roles
    state.apis = auth.apis
  },
  [types.SET_TENANTS](state: any, tenants: Array<any>) {
    state.tenants = tenants
  },
  [types.SET_REDIRECT_AFTER_LOGIN](state: any, url: string) {
    state.redirectAfterLogin = url
  },
  [types.SET_KEEP_CONNECTED](state: any, value: boolean) {
    state.keepConnected = value
  },
  [types.SET_SUBDOMAIN_STATUS](state: AuthState, status: SubDomainStatus) {
    state.subDomainStatus = status
  },
  [types.SET_USER_LOGGED](state: AuthState, userLogged: UserLogged) {
    state.userlogged = userLogged
  },
  [types.SET_USER_TOKEN](state: AuthState, userToken: string) {
    state.jwtoken = userToken
  },
  [types.SET_ID_OCTADESK](state: AuthState, idOctadesk: number) {
    state.idOctadesk = idOctadesk
  },
  [types.SET_PREV_LOGIN_TYPE](state: AuthState, loginType: string) {
    state.prevLoginType = loginType
  },
  [types.SET_LOGIN_TYPE](state: AuthState, loginType: string) {
    state.loginInfo!.type = loginType
  },
  [types.SET_EMAIL](state: AuthState, email: string) {
    state.loginInfo!.email = email
  },
  [types.SET_EMAIL_LOGIN_INFO](state: AuthState, userInfo: any) {
    state.loginInfo!.email = userInfo.email
    state.loginInfo!.password = userInfo.password
  },
  [types.SET_GOOGLE_TOKEN](state: AuthState, token: string) {
    state.loginInfo!.token = token
  },
  [types.SET_LEGACY_LOGIN_INFO](state: AuthState, token: string) {
    state.loginInfo!.token = token
  },
  [types.SET_TENANT_ID](state: AuthState, tenantId: string) {
    state.loginInfo!.tenantId = tenantId
  },
  [types.SET_TENANT_NAME](state: AuthState, tenantName: string) {
    state.loginInfo!.tenantName = tenantName
  },
  [types.SET_COMPANY_MODEL](state: AuthState, model: Record<string, any>) {
    state.companyModel = model
  },
  [types.SET_CONNECTION_STATUS](
    state: AuthState,
    status: ChatConnectionStatus
  ) {
    state.connectionStatus = status
  },
  [types.SET_TRIAL_OVER_MODAL](state: AuthState, trialOverModal: boolean) {
    state.trialOverModal = trialOverModal
  },
  [types.SET_PENDING_PAYMENT_MODAL](
    state: AuthState,
    pendingPaymentModal: boolean
  ) {
    state.pendingPaymentModal = pendingPaymentModal
  },
  [types.SET_CURRENT_AGENT_APPS_IDS](state: AuthState, ids: Array<string>) {
    state.currentAgentAppsIds = ids
  },
  [types.SET_TENANT_CONFIGS](state: AuthState, tenantConfigs: TenantConfigs[]) {
    state.tenantConfigs = tenantConfigs
  },
  [types.SET_MIGRATED_ENVIRONMENTED](
    state: AuthState,
    migratedEnvironment: boolean
  ) {
    state.migratedEnvironment = migratedEnvironment
  },
  [types.SET_PASSWORD_EXPIRED](state: AuthState, passwordExpired: boolean) {
    state.passwordExpired = passwordExpired
  },
  [types.SET_SSO_TENANTS](state: AuthState, ssoTenants: Array<SSOTenant>) {
    state.ssoTenants = ssoTenants
  },
  [types.SET_SHOULD_LOGOUT_ALERT](state: AuthState, value: boolean) {
    state.shouldLogout = value
  },
  [types.SET_TWO_FACTOR_AUTH_PENDING](state: AuthState, isPending: boolean) {
    state.hasTwoFactorAuthPending = isPending
  }
}
