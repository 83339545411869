import { ActionContext } from 'vuex'

import { useStore } from '@/modules/Billing'
import { fetchSummary } from '@/modules/Billing/api-services'

import { getAuthState } from '@/common/services/storage'

import { RootState } from '@/store/interfaces'

import * as types from './mutation-types'
import { BillingStates } from './types'

export const loadInitial = (
  context: ActionContext<BillingStates, RootState>
) => {
  if (!context.state.isInitialized) {
    return context.dispatch('loadSummary').then(r => {
      context.state.isInitialized = true
      return r
    })
  }
}

export const loadSummary = async (
  context: ActionContext<BillingStates, RootState>
) => {
  const authState = getAuthState()
  const tenantId =
    authState?.octaAuthenticated?.tenantId ||
    useStore().mapGetters(['tenantId'])
  return fetchSummary(`${tenantId}`).then(payload => {
    context.commit(types.LOAD_SUMMARY, payload)
    return payload
  })
}

export const setBulkSelectedAgents = async (
  context: ActionContext<BillingStates, RootState>,
  payload: any
) => {
  return context.commit(types.SET_BULK_SELECTED_AGENTS, payload)
}
