export const BILLING_STORE_NS = 'billing'
export const FIRST_BUY_ROOT = 'first-buy'

export enum BILLING_PAYMENT_METHODS {
  CREDIT_CARD = 'credit-card',
  BOLETO = 'bank-slip'
}

export enum BILLING_STORE_ACTIONS {
  LOAD_INITAL = 'LOAD_INITIAL',
  LOAD_SUMMARY = 'LOAD_SUMMARY',
  SET_TRIAL = 'SET_TRIAL',
  SET_UNLOGGED_INFO = 'SET_UNLOGGED_INFO'
}

export enum BILLING_FEATURES {
  WHATSAPP_API_NUMBER = 'whatsapp-api',
  WHATSAPP_WEB_NUMBER = 'whatsapp',
  WHATSAPP_MESSAGE = 'whatsapp-api-pkg-msg-single-250',
  OCTABSP_MESSAGE = 'whatsapp-api-pkg-msg-octa-bsp-single-250',
  VIP_MESSAGE = 'whatsapp-api-pkg-msg-octa-vip-single-250',
  WOZ_TRANSCRIPT = 'woz-transcript',
  NEW_TICKET = 'new-octa-ticket',
  USER = 'user'
}

export enum BILLING_STORAGE {
  BOLETO_CODE = 'saveInfoPayment'
}

export enum FIRST_BUY_ROUTES {
  SELECT_PLAN = 'billing.plan-choose.select-plan',
  ADDITIONALS = 'billing.plan-choose.additionals',
  BILLING_INFO = 'billing.plan-choose.billing-info',
  BUY_RESUME = 'billing.plan-choose.resume',
  PAYMENT = 'billing.plan-choose.payment',
  APPROVED_PAYMENT = 'billing.approved-payment'
}

export enum CHANGE_PLAN_ROUTES {
  SELECT_PLAN = 'settings.change-plan'
}

export enum OCTA_STORE_ROUTES {
  OCTA_STORE = 'octa-store'
}

export enum BILLING_ROUTES {
  SIGNATURE = 'settings.signature',
  BILLING_DATA = 'settings.data',
  LICENSE = 'settings.license',
  WHATSAPP = 'chat-settings-whatsapp-oficial-numbers',
  INVOICES = 'settings.invoice',
  WHATSAPP_BUY = 'settings.whatsapp.buy',
  CANCEL = 'settings.cancel',
  FIRST_BUY = 'settings.plan-choose.select-plan',
  CHECKOUT_RESUME = 'settings.checkout.resume',
  CHANGE_PAYMENT = 'settings.checkout-change'
}

export enum ADDITIONALS_ROUTES {
  ADDITIONALS = 'billing.additionals',
  SUMMARY = 'billing.additionals.summary',
  PAYMENT = 'billing.payment',
  PAYMENT_SUCCESS = 'billing.additionals.payment-success',
  CHECKOUT_ADDITIONALS_SUCCESS = 'billing.additionals.success',
  TICKET_LANDING_PAGE = 'ticket-lp'
}

export enum BILLING_USERS {
  USERS_MANAGEMENT = 'billing.users.management',
  USERS_PREVIEW = 'billing.users-preview',
  CHECKOUT_PREVIEW = 'billing.checkout-preview',
  CHECKOUT_PAYMENT = 'billing.checkout-payment',
  PAYMENT_SUCCESS = 'billing.payment-success'
}

export enum PERMISSION_ROUTES {
  CREATE = 'create-access-profile',
  EDIT = 'edit-access-profile'
}

export enum INVOICE_STATUS {
  UNPAID,
  PAID,
  CANCELLED
}

export enum PROFILES_ACTION_METHODS {
  EDIT = 'edit',
  DELETE = 'delete'
}

export enum PROFILES_ACTION_TYPE {
  NEW = 'new',
  EXISTING = 'existing'
}

export enum LICENSE_MANAGEMENT_TABS {
  USERS,
  PROFILES
}

export const LICENSE_MANAGEMENT_TABS_STRING: Record<
  LICENSE_MANAGEMENT_TABS,
  string
> = {
  [LICENSE_MANAGEMENT_TABS.USERS]: 'users',
  [LICENSE_MANAGEMENT_TABS.PROFILES]: 'permissions'
}
