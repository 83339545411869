import { BillingStates } from './types'

const state: BillingStates = {
  isInitialized: false,
  coupon: undefined,
  items: [],
  paymentMethod: {
    boleto: false,
    cardBrand: '',
    cardExpiration: '',
    cardNumber: ''
  },
  nextPayment: {
    currency: '',
    dueDate: '',
    proRata: 0,
    recurring: 0,
    total: 0
  },
  plan: {
    benefits: [],
    code: '',
    description: '',
    discount: 0,
    features: [],
    name: '',
    price: 0,
    saving: 0
  },
  tenant: undefined,
  bulkSelectedUsers: []
}

export default state
