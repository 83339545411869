import { headers } from '@octadesk-tech/services'

import { WozContentPageInformation } from '@/modules/Woz/content/store/interfaces'
import { getAiAnalyticsApiClient } from '@/modules/Woz/services/ai-analytics-client'
import { getAiApiClient } from '@/modules/Woz/services/http'

export const uploadDocuments = async (payload: any) => {
  try {
    const { data } = await getAiApiClient().then(client =>
      client.post('/documents/upload', payload, {
        headers: {
          ...headers.getAuthorizedHeaders().headers
        }
      })
    )

    return data
  } catch (ex) {
    throw new Error('Error on get woz related memories', { cause: ex })
  }
}

export const getDocuments = async (query: string, page = 1, pageSize = 30) => {
  try {
    const pagePortion = `page=${page}&pagesize=${pageSize}`

    const response = await getAiApiClient().then(client =>
      client.get(
        `/documents${
          query ? '?' + query + '&' + pagePortion : '?' + pagePortion
        }`,
        {
          headers: {
            ...headers.getAuthorizedHeaders().headers
          }
        }
      )
    )

    const paginationInfo = getPaginationInfo(response.headers, page)

    return { documents: response.data, paginationInfo }
  } catch (ex) {
    throw new Error('Error on get woz documents', { cause: ex })
  }
}

export const getPaginationInfo = (
  headers: any,
  page: number
): WozContentPageInformation => {
  return {
    kbItemsCount: Number.parseInt(headers['x-total-kb-items'] || '0'),
    totalPages: Number.parseInt(headers['x-total-pages'] || '0'),
    totalItems: Number.parseInt(headers['x-total-items'] || '0'),
    hasNextPage: headers['x-next-page'] === 'True',
    page
  }
}

export const getAgentDocuments = async (
  agentId: string,
  query: string,
  page = 1,
  pageSize = 30
) => {
  try {
    const pagePortion = `page=${page}&pagesize=${pageSize}`

    const response = await getAiApiClient().then(client =>
      client.get(
        `/documents/agent/${agentId}/${
          query ? '?' + query + '&' + pagePortion : '?' + pagePortion
        }`,
        {
          headers: {
            ...headers.getAuthorizedHeaders().headers
          }
        }
      )
    )

    const paginationInfo = getPaginationInfo(response.headers, page)

    return { documents: response.data, paginationInfo }
  } catch (ex) {
    throw new Error('Error on get agent woz documents', { cause: ex })
  }
}

export const getDocument = async (id: string) => {
  try {
    const { data } = await getAiApiClient().then(client =>
      client.get(`/documents/${id}`, {
        headers: {
          ...headers.getAuthorizedHeaders().headers
        }
      })
    )

    return data
  } catch (ex) {
    throw new Error('Error on get woz document', { cause: ex })
  }
}

export const updateActiveDocument = async (id: string, activate: boolean) => {
  try {
    await getAiApiClient().then(client =>
      client.patch(
        `/documents/${id}/${activate ? 'enable' : 'disable'}`,
        null,
        {
          headers: {
            ...headers.getAuthorizedHeaders().headers
          }
        }
      )
    )
  } catch (ex) {
    throw new Error('Error on update document active', { cause: ex })
  }
}

export const toggleKBDocuments = async (activate: boolean) => {
  try {
    await getAiApiClient().then(client =>
      client.patch(`/documents/${activate ? 'enable' : 'disable'}-kb`, null, {
        headers: {
          ...headers.getAuthorizedHeaders().headers
        }
      })
    )
  } catch (ex) {
    throw new Error('Error on update document active', { cause: ex })
  }
}

export const toggleLinkKBDocumentsAgent = async (
  agentId: string,
  activate: boolean
) => {
  try {
    await getAiApiClient().then(client =>
      client.patch(
        `/documents/agent/${agentId}/${activate ? 'enable' : 'disable'}-kb`,
        null,
        {
          headers: {
            ...headers.getAuthorizedHeaders().headers
          }
        }
      )
    )
  } catch (ex) {
    throw new Error('Error on update kb document active', { cause: ex })
  }
}

export const bulkActiveDocument = async (ids: string[], activate: boolean) => {
  try {
    await getAiApiClient().then(client =>
      client.patch(
        `/documents/bulk`,
        {
          ids,
          active: activate
        },
        {
          headers: {
            ...headers.getAuthorizedHeaders().headers
          }
        }
      )
    )
  } catch (ex) {
    throw new Error('Error on bulk documents update', { cause: ex })
  }
}

export const deleteDocument = async (id: string) => {
  try {
    await getAiApiClient().then(client =>
      client.delete(`/documents/${id}`, {
        headers: {
          ...headers.getAuthorizedHeaders().headers
        }
      })
    )
  } catch (ex) {
    throw new Error('Error on delete document', { cause: ex })
  }
}

export const bulkDelete = async (ids: string[]) => {
  try {
    await getAiApiClient().then(client =>
      client.patch(
        `/documents/bulk-delete`,
        { ids },
        {
          headers: {
            ...headers.getAuthorizedHeaders().headers
          }
        }
      )
    )
  } catch (ex) {
    throw new Error('Error on bulk delete document', { cause: ex })
  }
}

export const linkContentToAgent = async (
  agentId: string,
  documentIds?: Array<string>
) => {
  try {
    const { data } = await getAiApiClient().then(client =>
      client.post(
        `/documents/agent/${agentId}`,
        {
          documentIds
        },
        {
          headers: {
            ...headers.getAuthorizedHeaders().headers
          }
        }
      )
    )

    return data
  } catch (ex) {
    throw new Error('Error on link content to woz agent', { cause: ex })
  }
}

export const unlinkAgentDocuments = async (agentId: string, ids: string[]) => {
  try {
    await getAiApiClient().then(client =>
      client.patch(
        `/documents/bulk-delete/agent/${agentId}`,
        {
          ids
        },
        {
          headers: {
            ...headers.getAuthorizedHeaders().headers
          }
        }
      )
    )
  } catch (ex) {
    throw new Error('Error on bulk delete link agent to documents', {
      cause: ex
    })
  }
}

export const updateActiveLinkDocumentAgent = async (
  agentId: string,
  id: string,
  activate: boolean
) => {
  try {
    await getAiApiClient().then(client =>
      client.patch(
        `/documents/agent/${agentId}/${id}/${activate ? 'enable' : 'disable'}`,
        null,
        {
          headers: {
            ...headers.getAuthorizedHeaders().headers
          }
        }
      )
    )
  } catch (ex) {
    throw new Error('Error on update document active on agent link', {
      cause: ex
    })
  }
}

export const getContentGaps = async (
  search = '',
  page = 1,
  sortDirection: string | undefined = undefined
) => {
  try {
    let sort = ''
    if (sortDirection) {
      sort += sortDirection ? `&sort=${sortDirection}` : ''
    }

    const response = await getAiAnalyticsApiClient().then(client =>
      client.get(`/contents/break?page=${page}&text=${search}${sort}`, {
        headers: {
          ...headers.getAuthorizedHeaders().headers
        }
      })
    )
    return response.data
  } catch (ex) {
    throw new Error('Error on get content gaps', { cause: ex })
  }
}

export const markAsResolved = async (
  keys: { key: string; subKey: string }[]
) => {
  try {
    return await getAiAnalyticsApiClient().then(client =>
      client.patch(`/contents/break/resolve`, keys, {
        headers: {
          ...headers.getAuthorizedHeaders().headers
        }
      })
    )
  } catch (ex) {
    throw new Error('Error on mark as resolved')
  }
}
