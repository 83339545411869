import { AutomationsState, Event } from './interfaces'
import * as types from './mutations-types'

export default {
  [types.SET_EVENTS](state: AutomationsState, events: Array<Event>) {
    state.events = events
  },
  [types.CREATE_EVENTS](state: AutomationsState, events: Array<Event>) {
    state.events = events
  },
  [types.UPDATE_EVENTS](state: AutomationsState, events: Array<Event>) {
    state.events = events
  }
}
